<template>
  <div class="centered">
    <div class="flex min-w-modal">
      <div class="w-4/6 flex flex-col justify-between border-r">
        <div class="">
          <div class="text-center border-b my-6">
            <h2 class="text-lg mb-6">
              Casciari a la carta - JUE 10 AGOSTO
              <div class="text-xs">
                Empieza el jue., 10 de ago. de 2023 21:00 -03
              </div>
            </h2>
          </div>
          <div class="px-16">
            <div
              class="px-6 bg-white border rounded-lg dark:bg-gray-800 dark:border-gray-700 border-blue-500 border-2 mb-6"
            >
              <div
                class="flex items-center justify-between border-b px-6 py-3 -mx-6"
              >
                <div>Entrada general</div>
                <div><input-minus-plus></input-minus-plus></div>
              </div>
              <div class="text-sm dark:text-gray-400 py-6">
                <div class="font-medium">
                  $ 4.000
                  <span class="font-light text-gray-600 text-xs"
                    >+ $265,72 Costo de servicio</span
                  >
                </div>
                <div class="mt-3 text-sm font-light text-gray-500">
                  Entradas sin numerar, por orden de llegada. Las mesas pueden
                  ser compartidas.
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="border-t flex justify-between items-center px-10">
          <div class="text-xs text-gray-700">Volver</div>
          <!-- Boton  -->
          <div>
            <button
              class="w-full mt-4 text-white bg-gradient-to-r from-blue-500 via-blue-600 to-blue-700 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-blue-300 dark:focus:ring-blue-800 font-medium rounded text-sm px-5 py-2.5 text-center mr-2 mb-2"
            >
              Conseguir entradas
            </button>
          </div>
        </div>
      </div>
      <div class="w-2/6">
        <div>
          <img
            src="https://img.evbuc.com/https%3A%2F%2Fcdn.evbuc.com%2Fimages%2F472489619%2F168299195030%2F1%2Foriginal.20230319-133104?w=940&auto=format%2Ccompress&q=75&sharp=10&rect=0%2C0%2C2160%2C1080&s=9d52c052f8d6f33caae7341f7066feac"
          />
        </div>
        <div class="text-sm mx-auto p-6">
          <h2 class="text-sm font-medium mb-4">Resumen del Pedido</h2>

          <div class="flex justify-between mb-3">
            <p>1 x Entrada general</p>
            <p class="font-semibold">$ 4.000,00</p>
          </div>

          <hr class="my-2" />

          <div class="flex justify-between mb-3">
            <p>Subtotal</p>
            <p class="font-semibold">$ 4.000,00</p>
          </div>

          <div class="flex justify-between mb-3">
            <p>Costos</p>
            <p class="font-semibold">$ 265,72</p>
          </div>

          <hr class="my-2" />

          <div class="flex justify-between mt-2">
            <p class="font-semibold">Total</p>
            <p class="font-semibold">$ 4.265,72</p>
          </div>

          <p class="mt-2 text-xs font-light text-gray-700 text-right">
            El precio incluye impuestos
          </p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputMinusPlus from "@/components/tienda/InputMinusPlus.vue";
export default {
  layout: "empty",
  components: {
    InputMinusPlus,
  },
};
</script>

<style>
.text-xxs {
  font-size: 0.6rem !important;
}
.centered {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100vh;
  background: rgb(66, 66, 66);
}

.min-w-modal {
  margin-top: 5vh;
  max-width: 1024px;
  max-height: 768px;
  min-height: 768px;
  background: white;
  border: 1px solid rgb(228, 228, 228);
}
</style>